import { flatMap } from "lodash";

import {
    RatingNameAndValue,
    EnumTotals,
    ShopRating,
    StarTotals,
    FeedbackTagCount,
    OverallRatingsEnum
} from "./../types";
import { CustomerFeedback, OverallRatings, POSITIVE_SHOP_FEEDBACK, NEGATIVE_SHOP_FEEDBACK, SHOP_FEEDBACK } from "Types";
import { roundTo } from "NumberUtils";

/**
 * Function to return an empty ratings object
 * @returns Empty Ratings object
 */
export const getEmptyOverallRatings = (): OverallRatings => {
    return {
        noOfFiveStars: 0,
        noOfFourStars: 0,
        noOfThreeStars: 0,
        noOfTwoStars: 0,
        noOfOneStars: 0,
        noOfReviews: 0
    };
};

/**
 * Generic function to return initial object based on counting values from an enum
 * all keys from enum will be the properties in an object and the entries will be 0
 * @param enumKeys
 * @returns
 */
export const initialiseEnumTotals = <T extends unknown>(enumKeys: { [key: string]: T }): EnumTotals =>
    Object.keys(enumKeys).reduce((enumTotal: EnumTotals, enumKey: string) => {
        return {
            ...enumTotal,
            [enumKey]: 0
        };
    }, {});

/**
 * Function to get value of each of the ratings from object prop name
 * E.g noOfFiveStars = 5
 * @param {string} prop name of prop in rating
 * @returns {number} rating value
 */
export const getRatingEnumStarValueByKey = (prop: string) => {
    const rating = prop as keyof typeof RatingNameAndValue;
    return RatingNameAndValue[rating];
};

/**
 * return total of object overall star value or ratings
 * e.g if star value numberoffiveRatings * 5
 * e.g if overall rating number of times that rating has been given
 * @param {StarTotals | OverallRatings} starTotals
 * @returns {number} totals
 */
export const getTotalSumOfScoresOrReviews = (starTotals: StarTotals | OverallRatings) => {
    return (
        starTotals.noOfFiveStars +
        starTotals.noOfFourStars +
        starTotals.noOfThreeStars +
        starTotals.noOfTwoStars +
        starTotals.noOfOneStars
    );
};

/**
 * Function to add up combined shops in company view
 * @param {ShopRating[]} ShopRatings
 * @returns {OverallRatings} overall rating and reviews in all shops
 */
export const getCombinedShopRatings = (ShopRatings: ShopRating[]) => {
    const emptyTotals = getEmptyOverallRatings();
    /** go through each shop add totals into one overall total */
    return ShopRatings.reduce((totals: OverallRatings, shop: ShopRating) => {
        const overallRatings = shop.shopKPIs?.overallRatings;
        if (overallRatings) {        
            totals = {
                noOfReviews: (totals.noOfReviews += overallRatings.noOfReviews),
                noOfFiveStars: (totals.noOfFiveStars += overallRatings.noOfFiveStars),
                noOfFourStars: (totals.noOfFourStars += overallRatings.noOfFourStars),
                noOfThreeStars: (totals.noOfThreeStars += overallRatings.noOfThreeStars),
                noOfTwoStars: (totals.noOfTwoStars += overallRatings.noOfTwoStars),
                noOfOneStars: (totals.noOfOneStars += overallRatings.noOfOneStars)
            };
        }
        return totals;
    }, emptyTotals);
};

/**
 * Function to get average rating per shop (Overall) All reviews and ratings
 * @param {OverallRatings} overallRatings
 * @returns {number} Average score
 */
export const getScoreRatingFromTotals = (overallRatings: OverallRatings): number => {
    const ratingsOnly: Omit<OverallRatings, "score"> = {
        ...overallRatings
    };
    const initialiseTotals = initialiseEnumTotals(OverallRatingsEnum);
    const calculateOverallValues: EnumTotals = Object.entries(ratingsOnly).reduce(
        (totals: EnumTotals, [prop, value]: [string, number]) => {
            const inEnum = prop in RatingNameAndValue;

            /**Total score is for each star rating so say 5 (5 Stars) each star is equal
             * to 5 points, which it gets the points from an enum key using prop value
             * it mulitplies 1 * 5 = 25 etc. so 4 stars = 4 points e.g 1 * 4
             * ignores no of reviews (not in rating value enum)
             */
            const totalScore = inEnum ? value * getRatingEnumStarValueByKey(prop) : value;
            return {
                ...totals,
                [prop]: totalScore
            };
        },
        initialiseTotals
    );
    const starTotals = calculateOverallValues as StarTotals;
    /** get total overall star scores */
    const totalOverallStarScores = getTotalSumOfScoresOrReviews(starTotals);
    /** divide by number of reviews to get score */
    const average = totalOverallStarScores / starTotals.noOfReviews;
    /** make sure it is rounded to only one decimal */
    const roundToOne = roundTo(average, 1);
    /** if is a number return if not it's a 0 rating */
    return !isNaN(roundToOne) ? roundToOne : 0;
};

/**
 * Function to get average score from curent selection of feedback
 * @param {CustomerFeedback[]} customerFeedback current selection of feedback
 * @returns {OverallRatingsWithAverage}
 */
export const getScoreRatingFromCurrentFeedback = (customerFeedback: CustomerFeedback[]): OverallRatings => {
    const emptyTotals = getEmptyOverallRatings() as EnumTotals;
    const currentOverallRatings = customerFeedback.reduce((totals: EnumTotals, feedback: CustomerFeedback) => {
        /** rating from single feedback */
        const rating = feedback.overallRating;
        /** get prop / enum name from rating so 5 = noOr5Stars */
        const enumKey = RatingNameAndValue[rating];
        totals = {
            ...totals,
            [enumKey]: (totals[enumKey] += 1)
        };
        return totals;
    }, emptyTotals);
    let overallRatings = currentOverallRatings as unknown as OverallRatings;
    /** set from feedback how many reviews */
    overallRatings.noOfReviews = getTotalSumOfScoresOrReviews(overallRatings);
    /** calculate the actual score for the current set of feedback */
    const getCurrentFeedbackScore = getScoreRatingFromTotals(overallRatings);
    return {
        ...overallRatings,
        score: getCurrentFeedbackScore
    };
};

/**
 * Function to count all feedback tags in given customer feedback array
 * @param {CustomerFeedback[]} customerFeedback
 * @param {string} shopId
 * @returns {FeedbackTagCount} positive and negative feedback tag counts
 */
export const getMostSelectedFeedbackByShopId = (customerFeedback: CustomerFeedback[], shopId: string) => {
    /** initialising an object from the feedbabck enumerations with 0 */
    let feedbackCount = {
        shopId: shopId,
        positive: initialiseEnumTotals(POSITIVE_SHOP_FEEDBACK),
        negative: initialiseEnumTotals(NEGATIVE_SHOP_FEEDBACK)
    } as FeedbackTagCount;

    /** flatten the feedback tags from the feedback by shop id */
    const allFeedbackVallues = customerFeedback
        .filter((feedback: CustomerFeedback) => feedback.shopId === shopId)
        .map((value: CustomerFeedback) => {
            return value.feedback;
        });
    const flatenFeedbackArrays = flatMap(allFeedbackVallues);

    /** Count within feedback tags for positive or negative add to initialised object above*/
    const mostSelectedFeedbackTags = flatenFeedbackArrays.reduce((totals: FeedbackTagCount, tag: SHOP_FEEDBACK) => {
        const feedbackValue = tag as unknown as string;
        const isPostive = feedbackValue in POSITIVE_SHOP_FEEDBACK;
        const isNegative = feedbackValue in NEGATIVE_SHOP_FEEDBACK;
        if (isPostive) {
            const { positive } = totals;
            totals = {
                ...feedbackCount,
                positive: {
                    ...positive,
                    [feedbackValue]: (positive[feedbackValue] += 1)
                }
            };
        }
        /** probably no need for isNegative check,  but being specific for any one who reads this */
        if (isNegative) {
            const { negative } = totals;
            totals = {
                ...feedbackCount,
                negative: {
                    ...negative,
                    [feedbackValue]: (negative[feedbackValue] += 1)
                }
            };
        }
        return totals;
    }, feedbackCount);

    return mostSelectedFeedbackTags;
};

/**
 * return linear gradient with percentage for bars and stars in the feedback sections
 * @param {number} percentage
 * @returns {string} gradient
 */
export const getLinearGradient = (percentage: number) => {
    return `linear-gradient(90deg, rgba(255, 215, 0, 1) ${percentage}%, rgba(203, 213, 224, 1) ${percentage}%)`;
};

/**
 * Function to find out if every N row number up to every 5th row
 * Using this in the pagination to get a colour for each row
 * export enum RowColour {
    "purple.500" = 1, - first
    "indigo.500" = 2, - second
    "pink.500" = 3, - third
    "teal.500" = 4, - fourth
    "yellow.500" = 5 - fifth
}
 * @param {number} index current row index
 * @param {number} everyNrow how many rows before going back to the 1st in a row
 * @returns {number} every N Row number
 */
export const getNRowNumber = (index: number, everyNRow: number): number => {
    const first = index % everyNRow === 0 ? 1 : null;
    const second = (index + 4) % everyNRow === 0 ? 2 : null;
    const third = (index + 3) % everyNRow === 0 ? 3 : null;
    const fourth = (index + 2) % everyNRow === 0 ? 4 : null;
    const fifth = (index + 1) % everyNRow === 0 ? 5 : null;
    return (first || second || third || fourth || fifth) ?? 0;
};
