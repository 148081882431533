import { CustomerFeedback, OverallRatings, Shop } from "Types";

/** Query types */
export type ShopRating = Pick<Shop, "id" | "name" | "shopKPIs">;

export type ShopFeedbackQuery = {
    getCustomerFeedbackByShopIdsAndBetweenDates: CustomerFeedback[];
};

export type CompanyRatingsQuery = {
    getCompanyShops: ShopRating[];
};

export type ShopRatingsQuery = {
    getShopsByIds: ShopRating[];
};

/** View Enums + Types */
export enum FEEDBACK_VIEW {
    COMPANY_VIEW = "COMPANY_VIEW",
    SHOP_VIEW = "SHOP_VIEW",
    ADMINS_SHOPS_VIEW = "ADMINS_SHOPS_VIEW"
}

export enum OverallRatingsEnum {
    noOfFiveStars = "noOfFiveStars",
    noOfFourStars = "noOfFourStars",
    noOfThreeStars = "noOfThreeStars",
    noOfTwoStars = "noOfTwoStars",
    noOfOneStars = "noOfOneStars",
    noOfReviews = "noOfReviews"
}

export enum SCORE_TYPE {
    TOTAL = "TOTAL",
    LAST_30_DAYS = "LAST_30_DAYS"
}

export type Size = "sm" | "md" | "lg";
export type ScoreView = "OverallShop" | "OverallFeedback" | "ShopTile";

/** Calculated function types + enum */
export enum RatingNameAndValue {
    noOfFiveStars = 5,
    noOfFourStars = 4,
    noOfThreeStars = 3,
    noOfTwoStars = 2,
    noOfOneStars = 1
}

/** Row colours based on the row number */
export enum RowColour {
    "purple.500" = 1,
    "indigo.500" = 2,
    "pink.500" = 3,
    "teal.500" = 4,
    "yellow.500" = 5
}

/** Feedback and shop specific types */
export type FeedbackTagCount = {
    shopId: string;
    positive: EnumTotals;
    negative: EnumTotals;
};

export type EnumTotals = {
    [key: string]: number;
};

export type StarTotals = OverallRatings;

export type ShopRatingAndFeedbackCount = {
    shopRating: ShopRating;
    feedbackCount: FeedbackTagCount;
};
