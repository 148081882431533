import React, { useContext, useState, useEffect, useMemo, PropsWithChildren } from "react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

import {
    CompanyRatingsQuery,
    FEEDBACK_VIEW,
    SCORE_TYPE,
    ShopFeedbackQuery,
    ShopRating,
    ShopRatingsQuery
} from "./types";
import { useMothershipLazyQuery } from "Hooks";
import {
    GET_CUSTOMER_FEEDBACK_BY_SHOP_IDS_BETWEEN_DATES,
    GET_SHOP_RATINGS_BY_COMPANY_ID,
    GET_SHOP_RATING_BY_SHOP_IDS
} from "GraphQLQueries";
import { useQopla } from "Providers";
import { isAtLeastCompanyAdmin, isSuperAdmin, parseDateForJavaLocalDateTime } from "Utils";
import { CustomerFeedback } from "Types";
import { getScoreRatingFromTotals } from "./utils/feedbackFunctions";
import { sortByOverallScore } from "./utils/sortingShopSections";
import { sortByFeedbackRating } from "./utils/sortingFeedbackSection";
import { SelectOption } from "Molecules";
import { useQoplaStore } from "Stores";

interface ICustomerFeedback {
    companyShops?: ShopRating[];
    selectedShops?: ShopRating[];
    currentFeedback?: CustomerFeedback[];
    feedbackView: FEEDBACK_VIEW;
    feedbackDates: FeedbackDates;
    scoreType: SCORE_TYPE;
    awaitingFeedback: boolean;
    awaitingSelectedShopsRating: boolean;
    awaitingAllShopsRating: boolean;
    allowedResturants: SelectOption[];
    selectedShopId: string;
    switchToAllowedFeedbackView: () => void;
    setScoreType: React.Dispatch<React.SetStateAction<SCORE_TYPE>>;
    setFeedbackDates: React.Dispatch<React.SetStateAction<FeedbackDates>>;
    onHandleSelectedShop: (shopId: string) => void;
}

export const FeedbackContext = React.createContext<ICustomerFeedback>(null as any);

type MomentDate = moment.Moment;

export type FeedbackDates = {
    fromDate: MomentDate;
    toDate: MomentDate;
};

type Props = {
    initialFeedbackView: FEEDBACK_VIEW;
    initialDates: FeedbackDates;
} & PropsWithChildren;

const FeedbackDataProvider: React.FC<Props> = ({ initialFeedbackView, initialDates, children }) => {
    const { authenticatedUser } = useQopla();
    const { selectedCompany, selectedShop } = useQoplaStore();
    const location = useLocation();
    const navigate = useNavigate();
    const { search } = location;

    const [selectedShopId, setSelectedShopId] = useState<string>(() => {
        if (search) {
            const id = new URLSearchParams(search).get("id") || selectedShop?.id || "";
            if (id) {
                return id;
            }
        }
        return selectedShop?.id || "";
    });
    const [feedbackView, setFeedbackView] = useState<FEEDBACK_VIEW>(initialFeedbackView);
    const [collectFeedback, setCollectFeedback] = useState<boolean>(false);
    const [feedbackDates, setFeedbackDates] = useState<FeedbackDates>(() => {
        if (search) {
            const params = new URLSearchParams(search);
            const from = params.get("from");
            const to = params.get("to");
            if (from && to) {
                return {
                    fromDate: moment(from),
                    toDate: moment(to)
                };
            }
        }
        return initialDates;
    });
    const [scoreType, setScoreType] = useState<SCORE_TYPE>(SCORE_TYPE.TOTAL);
    const [mulitpleCompanyShops, setMultipleCompanyShops] = useState<SelectOption[]>([]);
    const [allowedFeedbackView, setAllowedFeedbackView] = useState<FEEDBACK_VIEW | null>(() => {
        if (initialFeedbackView !== FEEDBACK_VIEW.SHOP_VIEW) {
            return initialFeedbackView;
        }
        return null;
    });

    // COMPANY_VIEW - SHOP RATINGS - Company Admin User
    const [getShopsByCompany, { data: companyShopsRatingData, loading: allShopsLoading }] =
        useMothershipLazyQuery<CompanyRatingsQuery>(GET_SHOP_RATINGS_BY_COMPANY_ID);

    // SINGLE / Mutliple SHOP RATINGS - Admin User
    const [getShopRatings, { data: shopRatingsData, loading: selectedshopsLoading }] =
        useMothershipLazyQuery<ShopRatingsQuery>(GET_SHOP_RATING_BY_SHOP_IDS);

    // FEEDBACK BY SHOPIDS - Between two dates (default last 30 days)
    const [getFeedback, { data: feedbackData, loading: feedbackLoading }] = useMothershipLazyQuery<ShopFeedbackQuery>(
        GET_CUSTOMER_FEEDBACK_BY_SHOP_IDS_BETWEEN_DATES
    );

    const companyShops = useMemo(() => {
        return companyShopsRatingData?.getCompanyShops
            .filter((shop: ShopRating) => {
                if (isSuperAdmin(authenticatedUser.roles)) {
                    return true;
                } else {
                    return authenticatedUser.shopIds.includes(shop.id);
                }
            })
            .map((shop: ShopRating, _) => {
                if (shop.shopKPIs?.overallRatings) {
                    return {
                        ...shop,
                        shopKPIs: {
                            overallRatings: {
                                ...shop.shopKPIs.overallRatings,
                                score: getScoreRatingFromTotals(shop.shopKPIs?.overallRatings)
                            }
                        }
                    };
                }
                return shop;
            })
            .sort(sortByOverallScore);
    }, [companyShopsRatingData]);

    const selectedShops = useMemo(() => {
        return shopRatingsData?.getShopsByIds
            .map((shop: ShopRating, _) => {
                if (shop.shopKPIs?.overallRatings) {
                    return {
                        ...shop,
                        shopKPIs: {
                            overallRatings: {
                                ...shop.shopKPIs.overallRatings,
                                score: getScoreRatingFromTotals(shop.shopKPIs?.overallRatings)
                            }
                        }
                    };
                }
                return shop;
            })
            .sort(sortByOverallScore);
    }, [shopRatingsData]);

    const currentFeedback = useMemo(() => {
        return feedbackData?.getCustomerFeedbackByShopIdsAndBetweenDates.sort(sortByFeedbackRating);
    }, [feedbackData]);

    const onGetFeedback = (shopIds: string[]) => {
        getFeedback({
            variables: {
                shopIds: shopIds,
                fromDate: parseDateForJavaLocalDateTime(feedbackDates.fromDate.startOf("day")),
                toDate: parseDateForJavaLocalDateTime(feedbackDates.toDate.endOf("day"))
            }
        });
    };

    const onGetShopRatings = (shopIds: string[]) => {
        getShopRatings({
            variables: {
                shopIds: shopIds
            }
        });
    };

    const onHandleSelectedShop = (shopId: string, fromCompanyView: boolean = true) => {
        setSelectedShopId(shopId);
        setFeedbackView(FEEDBACK_VIEW.SHOP_VIEW);
    };

    const onInitialShopView = () => {
        if (initialFeedbackView === FEEDBACK_VIEW.SHOP_VIEW) {
            getShopsByCompany({
                variables: {
                    companyId: selectedCompany.id
                }
            });
        }
    };

    const onSetAllowedShops = (currentAllowedShops: ShopRating[]) => {
        const selectShopOptions: SelectOption[] = currentAllowedShops.map((value: ShopRating) => {
            return {
                label: value.name,
                value: value.id
            };
        });
        setMultipleCompanyShops(selectShopOptions);
    };

    const switchToAllowedFeedbackView = () => {
        if (allowedFeedbackView) {
            navigate(location.pathname, { replace: true });
            setSelectedShopId("");
            setFeedbackView(allowedFeedbackView);
        }
    };

    /** Setting allowed shops on initial view being company or admin */
    const onSetAllowedShopsInCompanyOrAdminView = () => {
        const currentAllowedShops = companyShops?.length ? companyShops : selectedShops;
        if (currentAllowedShops && currentAllowedShops.length) {
            const shopIds = currentAllowedShops?.filter((value: ShopRating) => {
                if (value.shopKPIs?.overallRatings) {
                    return value.shopKPIs?.overallRatings?.noOfReviews > 0;
                }
                return false;
            });
            onSetAllowedShops(shopIds);
        }
    };

    /** Setting allowed shops on initial view being shop view */
    const onSetAllowedShopInShopView = () => {
        let shops: ShopRating[] = [];
        if (isSuperAdmin(authenticatedUser.roles) || isAtLeastCompanyAdmin(authenticatedUser)) {
            shops =
                companyShops?.filter((value: ShopRating) => {
                    if (value.shopKPIs?.overallRatings) {
                        return value.shopKPIs?.overallRatings?.noOfReviews > 0;
                    }
                    return false;
                }) ?? [];
            setAllowedFeedbackView(FEEDBACK_VIEW.COMPANY_VIEW);
        } else {
            shops =
                companyShops?.filter((value: ShopRating) => {
                    if (authenticatedUser.shopIds.includes(value.id)) {
                        if (value.shopKPIs?.overallRatings) {
                            return value.shopKPIs?.overallRatings?.noOfReviews > 0;
                        }
                        return false;
                    }
                    return false;
                }) ?? [];
            setAllowedFeedbackView(FEEDBACK_VIEW.ADMINS_SHOPS_VIEW);
        }
        onSetAllowedShops(shops);
    };

    useEffect(() => {
        if (feedbackView === FEEDBACK_VIEW.SHOP_VIEW) {
            navigate(
                `?id=${selectedShopId}&from=${feedbackDates.fromDate.format(
                    "YYYY-MM-DD"
                )}&to=${feedbackDates.toDate.format("YYYY-MM-DD")}`
            );
        }
    }, [selectedShopId, feedbackDates]);

    useEffect(() => {
        if (feedbackView) {
            switch (feedbackView) {
                case FEEDBACK_VIEW.COMPANY_VIEW: {
                    getShopsByCompany({
                        variables: {
                            companyId: selectedCompany.id
                        }
                    });
                    setScoreType(SCORE_TYPE.TOTAL);
                    break;
                }
                case FEEDBACK_VIEW.SHOP_VIEW: {
                    onGetShopRatings([selectedShopId]);
                    setScoreType(SCORE_TYPE.LAST_30_DAYS);
                    onInitialShopView();
                    break;
                }
                case FEEDBACK_VIEW.ADMINS_SHOPS_VIEW: {
                    onGetShopRatings(authenticatedUser.shopIds);
                    setScoreType(SCORE_TYPE.TOTAL);
                    break;
                }
            }
            setCollectFeedback(true);
        }
    }, [feedbackView, selectedShopId]);

    useEffect(() => {
        if (collectFeedback) {
            let shopIds: string[] = [];
            if (feedbackView !== FEEDBACK_VIEW.COMPANY_VIEW) {
                if (feedbackView === FEEDBACK_VIEW.ADMINS_SHOPS_VIEW) {
                    shopIds = authenticatedUser.shopIds;
                } else {
                    shopIds = [selectedShopId];
                }
            } else if (feedbackView === FEEDBACK_VIEW.COMPANY_VIEW && companyShops) {
                shopIds = companyShops.map((shop: ShopRating) => {
                    return shop.id;
                });
            }
            onGetFeedback(shopIds);
        }
    }, [collectFeedback, companyShops, selectedShops]);

    useEffect(() => {
        if (feedbackDates.fromDate !== initialDates.fromDate || feedbackDates.toDate !== initialDates.toDate) {
            onGetFeedback([selectedShopId]);
        }
    }, [feedbackDates]);

    useEffect(() => {
        if (initialFeedbackView !== FEEDBACK_VIEW.SHOP_VIEW && feedbackView !== FEEDBACK_VIEW.SHOP_VIEW) {
            onSetAllowedShopsInCompanyOrAdminView();
        } else if (initialFeedbackView === FEEDBACK_VIEW.SHOP_VIEW && companyShops) {
            onSetAllowedShopInShopView();
        }
    }, [initialFeedbackView, companyShops, selectedShops]);

    return (
        <FeedbackContext.Provider
            value={{
                selectedShops,
                companyShops,
                currentFeedback,
                feedbackView,
                feedbackDates,
                awaitingFeedback: feedbackLoading,
                awaitingAllShopsRating: allShopsLoading,
                awaitingSelectedShopsRating: selectedshopsLoading,
                allowedResturants: mulitpleCompanyShops,
                switchToAllowedFeedbackView,
                selectedShopId,
                setFeedbackDates,
                scoreType,
                setScoreType,
                onHandleSelectedShop
            }}
        >
            {children}
        </FeedbackContext.Provider>
    );
};

export const FeedbackConsumer = FeedbackContext.Consumer;
export const useFeedbackDataManager = () => useContext(FeedbackContext);

export { FeedbackDataProvider };
