import React from "react";

import { Header, Flex, Text, BaseBoxProps, Box } from "Atoms";
import { roundTo } from "NumberUtils";
import { getLinearGradient } from "../../utils/feedbackFunctions";
import { ScoreView, Size } from "../../types";
import { SwitchRatings } from "./SwitchRatings";

type Props = {
    size: Size;
    hasSwitch: boolean;
    scoreView: ScoreView;
    title?: string;
    currentScore?: number;
    noOfReviews?: number;
};

/** Used clip path for the star as I needed to linear gradient
 * not a simple task on an icon
 */
export const Star: React.FC<BaseBoxProps> = ({ ...props }) => {
    const clipPath = "polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)";
    return <Box {...props} style={{ clipPath: clipPath }} />;
};

export const OverallScore: React.FC<Props> = ({
    size,
    title,
    hasSwitch,
    currentScore = 0,
    noOfReviews = 0,
    scoreView
}) => {
    const isLargerSize = size === "lg";
    const hasDecimal = currentScore % 1 !== 0;

    let displayScore: string = "" + currentScore;
    if (currentScore !== 0) {
        displayScore = hasDecimal ? displayScore.replace(".", ",") : currentScore + ",0";
    }

    const headerSize = isLargerSize ? "3xl" : "xl";
    const starSizes = isLargerSize ? "2.5rem" : "1.5rem";
    const includeReviews = scoreView !== "ShopTile";

    return (
        <Flex direction="column" alignItems="center" justifyContent="center">
            <Header m={0} p={0} size={isLargerSize ? "md" : "sm"}>
                {title ? title : "Total"}
            </Header>
            <Header size={headerSize} m={0} p={0} marginTop="auto">
                {displayScore}
            </Header>
            {includeReviews && (
                <Text color="gray.900" letterSpacing="1px" textAlign="center" mb={1}>
                    {noOfReviews} omdömen
                </Text>
            )}
            <FiveStar
                widthAndHeight={starSizes}
                currentScore={currentScore}
                hasDecimal={hasDecimal}
                isLargerSize={isLargerSize}
            />
            {hasSwitch && <SwitchRatings view={scoreView} />}
        </Flex>
    );
};

export const FiveStar: React.FC<{
    widthAndHeight: string;
    currentScore: number;
    hasDecimal: boolean;
    isLargerSize: boolean;
}> = ({ widthAndHeight, currentScore, hasDecimal, isLargerSize }) => {
    const margin = isLargerSize ? 0 : 3;

    return (
        <Flex mb={margin} height="auto" justifyContent="center">
            {[...Array(5)].map((_, index: number) => {
                const starRating = (index += 1);
                const hasWholeStar = starRating <= currentScore;

                if (hasDecimal && !hasWholeStar) {
                    /** if the score is between whole numbers
                     * need a percentage of the star to be coloured gold
                     */
                    const partScore = starRating - currentScore;
                    if (partScore < 1) {
                        const gradientPercentage = roundTo((1 - partScore) * 100, 0);
                        const linearGradient = getLinearGradient(gradientPercentage);
                        return (
                            <Star
                                width={widthAndHeight}
                                height={widthAndHeight}
                                key={index}
                                background={linearGradient}
                            />
                        );
                    }
                }

                return (
                    <Star
                        width={widthAndHeight}
                        height={widthAndHeight}
                        key={index}
                        backgroundColor={hasWholeStar ? "gold" : "gray.500"}
                    />
                );
            })}
        </Flex>
    );
};
