import React, { useState, useEffect } from "react";
import moment from "moment";
import { IoMdThumbsUp } from "@react-icons/all-files/io/IoMdThumbsUp";
import { IoMdThumbsDown } from "@react-icons/all-files/io/IoMdThumbsDown";

import {
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    Drawer,
    DrawerOverlay,
    DrawerActions
} from "Organisms";
import { IDrawerComponentContext } from "Providers";
import { CustomerFeedback, ProductRating } from "Types";
import { FiveStar } from "../shared/OverallScore";
import { Box, Flex } from "Atoms";
import { useMothershipQuery } from "Hooks";
import { GET_DATE_OF_ORDER_BY_ID } from "GraphQLQueries";
import { formatDateToLocal } from "TempUtils";

type Props = {
    feedback: CustomerFeedback;
};

type Query = {
    getOrderById: {
        createdAt: string;
    };
};

type PurchaseDate = {
    localDate: string;
    time: string;
    day: string;
};

const DateBox = ({ ...props }) => (
    <Box
        py={2}
        px={4}
        m={2}
        userSelect="none"
        fontWeight="bold"
        cursor="pointer"
        fontSize={["md", "sm"]}
        borderRadius="lg"
        boxShadow="md"
        {...props}
    />
);

export const ProductFeedbackDrawer: React.FC<IDrawerComponentContext<Props>> = ({
    props: { feedback },
    onCloseDrawer
}) => {
    const [purchaseDate, setPurchasedDate] = useState<PurchaseDate | null>(null);

    const { data } = useMothershipQuery<Query>(GET_DATE_OF_ORDER_BY_ID, {
        variables: {
            orderId: feedback.orderId
        }
    });

    const onHandlePurchaseDate = (date: string) => {
        const localDateTime = formatDateToLocal(date);
        const hoursAndMinute = moment(localDateTime).format("HH:mm");
        const dayOfWeek = moment(localDateTime).locale("sv").format("dddd");
        const purchaseDate: PurchaseDate = {
            localDate: moment(localDateTime).format("YYYY-MM-DD"),
            time: hoursAndMinute,
            day: dayOfWeek
        };
        setPurchasedDate(purchaseDate);
    };

    useEffect(() => {
        if (data?.getOrderById?.createdAt) {
            onHandlePurchaseDate(data?.getOrderById?.createdAt);
        }
    }, [data]);

    return (
        <Drawer open onClose={onCloseDrawer}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader display="flex" justifyContent="center" alignItems="center">
                    <Box as="q" pt={6} pb={4}>
                        {feedback.additionalComments ? feedback.additionalComments : " "}
                    </Box>
                </DrawerHeader>
                <DrawerCloseButton top="15px" />
                <DrawerBody overflow="auto" pt="0">
                    <Flex direction="column" justifyContent="flex-start" alignItems="center">
                        <FiveStar
                            widthAndHeight="4rem"
                            hasDecimal={false}
                            currentScore={feedback.overallRating}
                            isLargerSize={false}
                        />
                        <DateBox>
                            <Flex height="auto">
                                <Box flex="1">Köpdatum:</Box>
                                <Box ml={6}>{purchaseDate?.localDate}</Box>
                            </Flex>
                            <Flex height="auto">
                                <Box flex="1">Tid:</Box>
                                <Box>{purchaseDate?.time}</Box>
                            </Flex>
                            <Flex height="auto">
                                <Box flex="1">Dag:</Box>
                                <Box textTransform="capitalize">{purchaseDate?.day}</Box>
                            </Flex>
                        </DateBox>
                        <Box mt={6}>
                            {feedback.productRatings?.map((product: ProductRating) => {
                                const hasThumbsUp = product.thumbsUp;
                                return (
                                    <Flex
                                        height="auto"
                                        mt={4}
                                        justifyContent="center"
                                        alignItems="center"
                                        key={product.refProductId || product.refBundleProductId}
                                    >
                                        <Box flex="1" mt={4}>
                                            {product.name}
                                            {product.bundleProductText && (
                                                <Box fontSize={"0.9rem"} color="gray.600" maxWidth={"95%"}>
                                                    {product.bundleProductText}
                                                </Box>
                                            )}
                                        </Box>
                                        <Box
                                            ml={4}
                                            fontSize="4rem"
                                            as={IoMdThumbsUp}
                                            color={
                                                hasThumbsUp === null ? "gray.500" : hasThumbsUp ? "gold" : "gray.500"
                                            }
                                        />
                                        <Box
                                            ml={4}
                                            fontSize="4rem"
                                            as={IoMdThumbsDown}
                                            color={
                                                hasThumbsUp === null ? "gray.500" : !hasThumbsUp ? "gold" : "gray.500"
                                            }
                                        />
                                    </Flex>
                                );
                            })}
                        </Box>
                    </Flex>
                </DrawerBody>
                <DrawerActions></DrawerActions>
            </DrawerContent>
        </Drawer>
    );
};
