import React from "react";

import { Flex, Header, Switch } from "Atoms";
import { useFeedbackDataManager } from "../../FeedbackDataProvider";
import { ScoreView, SCORE_TYPE } from "../../types";

export const SwitchRatings: React.FC<{ view: ScoreView }> = ({ view }) => {
    const { scoreType, setScoreType } = useFeedbackDataManager();

    return (
        <Flex mt={2} mr={4} alignSelf="flex-end" justifyContent="center" alignItems="center">
            <Header size="sm" m={0}>
                Total
            </Header>
            <Switch
                ml={2}
                mr={2}
                size="lg"
                isChecked={scoreType === SCORE_TYPE.LAST_30_DAYS}
                onChange={() => {
                    setScoreType(scoreType === SCORE_TYPE.TOTAL ? SCORE_TYPE.LAST_30_DAYS : SCORE_TYPE.TOTAL);
                }}
                themeColor="teal"
            />
            <Header size="sm" m={0}>
                {view === "OverallShop" ? "30 Dagar" : "Datum"}
            </Header>
        </Flex>
    );
};
